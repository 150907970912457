export const searchFilters = [
  { value: 'treatment.name', label: 'Nombre' },
  { value: 'treatment.price', label: 'Precio' },
  { value: 'treatment.sku', label: 'SKU' },
];

export const filters = {
  search: true,
  treatmentType: true,
  status: true,
};

export const searchInputInitialValues = {
  search: { filter: 'treatment.name' },
};

export const treatmentConst = {
  medical: 'Médico',
  cosmetologic: 'Cosmetológico'
};
