import { useEffect, useRef, useState } from 'react';
import { Alert, Button, Card, Form, Select, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDoctors,
  getCosmetologists,
  setOnProcess,
  finishAppointment,
  getAppointmentById,
  getStockByBranchAndTreatment,
} from '../../../../appRedux/actions';
import {
  appointmentConst,
  appointmentRoles,
  appointmentStatus,
  appointmentStatusConst,
  buttonLabel,
  cardConfig,
  formConfig,
  messages,
  oneColumnFormConfig,
  placeHolders,
} from '../../../../constants';
import { CustomSelect } from '../../../uiComponents';
import FormItem from 'antd/es/form/FormItem';
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { treatmentConst } from '../../../../constants/Treatments.cons';

export const StatusModalBody = ({ appointmentId, treatmentType, setIsModalOpen }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { selectedBranchId } = useSelector((state) => state.agenda);
  const { doctors, cosmetologists } = useSelector((state) => state.user);
  const { appointmentById } = useSelector((state) => state.agenda);
  const { stockForTreatment } = useSelector((state) => state.stock);

  const [response, setResponse] = useState();
  const [status, setStatus] = useState(false);
  const [consent, setConsent] = useState(false);
  const [needConsent, setNeedConsent] = useState(false);
  const [consentId, setConsentId] = useState();
  const [purchasedProduct, setPurchasedProduct] = useState();
  const [sessions, setSessions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [roleLabel, setRoleLabel] = useState();
  const [users, setUsers] = useState([]);
  const [statusFiltered, setStatusFiltered] = useState();
  const [role, setRole] = useState();
  const [refreshAppointment, setRefreshAppointment] = useState(false);
  const [requiredConsent, SetRequiredConsent] = useState(false);
  const [limit, setLimit] = useState(1);
  const [areasApplied, setAreasApplied] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const { scheduled, inProcess, finished } = appointmentStatus;
  const { treatment } = appointmentById ?? {};
  let filteredProducts = stockForTreatment?.filter(
    (product) => !selectedItems.includes(product.productId)
  );
  let selectedProducts = useRef({});

  useEffect(() => {
    dispatch(getAppointmentById(appointmentId));
  }, [dispatch, appointmentId, refreshAppointment]);

  useEffect(() => {
    if (appointmentById) {
      setRole(appointmentRoles[appointmentById?.treatmentType?.name]);
      SetRequiredConsent(!!appointmentById?.treatment?.consent);
      setLimit(appointmentById?.purchasedProduct?.limit);

      if (
        appointmentById?.treatment?.treatmentType?.name ===
          appointmentConst.DOCTOR ||
        appointmentById?.treatment?.treatmentType?.name ===
          appointmentConst.VALUATION
      ) {
        setRoleLabel(appointmentConst.DOCTOR);
      } else {
        setRoleLabel(appointmentConst.COSMETOLOGIST);
      }

      setStatusFiltered(
        appointmentStatusConst?.filter((status) => {
          switch (appointmentById?.status) {
            case scheduled?.alias:
              return status?.value !== finished?.alias;
            case inProcess?.alias:
              if (!appointmentById?.purchasedProduct) {
                return status?.value === inProcess?.alias;
              } else {
                return [finished?.alias].includes(status?.value);
              }
            default:
              return [];
          }
        })
      );
    }
  }, [appointmentById, finished?.alias, scheduled?.alias, inProcess?.alias]);

  useEffect(() => {
    if (roleLabel);
    setUsers(roleLabel === appointmentConst.DOCTOR ? doctors : cosmetologists);
  }, [roleLabel, doctors, cosmetologists]);

  useEffect(() => {
    if(treatmentType === treatmentConst.cosmetologic){
      dispatch(getCosmetologists(selectedBranchId));
    }
    else{
      dispatch(getDoctors(selectedBranchId));
    }
  }, [treatmentType, dispatch, role, selectedBranchId]);

  useEffect(() => {
    if ([200, 201].includes(response)) {
      setIsModalOpen(false);
    }
  }, [response, setIsModalOpen]);

  useEffect(() => {
    if (appointmentById?.status) {
      setStatus(appointmentById?.status);

      form.setFieldsValue({
        status: appointmentStatus[appointmentById?.status]?.value,
      });
    }
  }, [appointmentById.status, form]);

  useEffect(() => {
    if (
      appointmentById?.attendedBy &&
      Object.keys(appointmentById?.attendedBy).length > 0
    )
      form.setFieldsValue({
        attendedById: appointmentById?.attendedBy?.id,
      });
  }, [appointmentById.attendedBy, form]);

  useEffect(() => {
    if (appointmentById?.treatment) {
      setNeedConsent(appointmentById?.treatment?.consent === 1);
      setConsentId(appointmentById?.treatment?.treatmentConsent?.id);
    }
  }, [appointmentById]);

  useEffect(() => {
    if (appointmentById?.consent) {
      setConsent(appointmentById?.consent);
    }
  }, [appointmentById]);

  useEffect(() => {
    if (appointmentById?.purchasedProduct)
      setPurchasedProduct(appointmentById?.purchasedProduct);
  }, [appointmentById?.purchasedProduct]);

  useEffect(() => {
    if (appointmentById?.purchasedProduct) {
      const { sessions, currentSession } = appointmentById?.purchasedProduct;
      const { sessions: sessionsScheduled = 0 } = appointmentById;
      const leftSessions = sessions - currentSession + sessionsScheduled;

      const session = [];
      for (let index = 0; index < leftSessions; index++) {
        session.push({
          value: index + 1,
          label: index + 1,
        });
      }

      setSessions(session);
    }
  }, [appointmentById]);

  useEffect(() => {
    let result = [];
    if (appointmentById?.purchasedProduct?.session?.length > 0) {
      const sessions = appointmentById?.purchasedProduct?.session;
      const areasToUse = appointmentById?.purchasedProduct?.areas
        ? appointmentById?.purchasedProduct?.areas
        : appointmentById?.treatment?.areas;
      result = sessions.filter((session) =>
        areasToUse.map((area) => area.id).includes(session?.area?.id)
      );

      const selected = result.map((value) => value.area);

      setAreasApplied(() => {
        return removeDuplicates(selected);
      });
    }
  }, [appointmentById, limit]);

  useEffect(() => {
    const areasToUse = appointmentById?.purchasedProduct?.areas
      ? appointmentById?.purchasedProduct?.areas
      : appointmentById?.treatment?.areas;
    if (areasApplied.length >= limit) {
      setAreas(areasToUse.filter((area) => areasApplied.includes(area.id)));
    } else {
      setAreas(areasToUse);
    }
  }, [areasApplied, limit, appointmentById]);

  useEffect(() => {
    if (status === finished?.alias) {
      dispatch(getStockByBranchAndTreatment(selectedBranchId, treatment?.id));
    }
  }, [status, finished, dispatch, selectedBranchId, treatment]);

  const onAreaChange = () => {
    const sessionAreas = form
      .getFieldsValue()
      .sessionAreas.map((sA) => sA.areaId)
      .filter((sA) => sA !== undefined);
    removeDuplicates(sessionAreas);
    setAreasApplied(sessionAreas);
  };

  const removeDuplicates = (selectedAreas) => {
    return selectedAreas.filter(
      (value, index, self) =>
        index ===
        self.findIndex((t) => t.place === value.place && t.name === value.name)
    );
  };

  const downloadConsent = () => {
    window.open(
      `${process.env.REACT_APP_API}/v${process.env.REACT_APP_API_VERSION}/consent/${consentId}/${appointmentById?.id}`,
      '_blank',
      'noopener,noreferrer'
    );
  };

  const onSessionsChange = (sessions) => {
    selectedProducts.current = {};
    setSelectedItems([]);
    let productQuantity = 0;

    for (let i = 0; i < treatment?.treatmentProduct?.length; i++) {
      const product = treatment?.treatmentProduct[i];
      productQuantity += product.quantity;
    }

    const treatmentProducts = [];
    for (let i = 0; i < productQuantity * sessions; i++) {
      treatmentProducts.push('');
    }

    const sessionAreas = [];
    for (let i = 0; i < sessions; i++) {
      sessionAreas.push('');
    }

    form.setFieldsValue({ sessionAreas });
    form.setFieldsValue({ treatmentProducts });
  };

  const onProductChange = (_, product) => {
    const totalSelected = selectedProducts.current[product?.productId] ?? 0;

    if (product) {
      selectedProducts.current[product.productId] = totalSelected + 1;
    }

    const items = treatment?.treatmentProduct?.filter(
      (treatmentProduct) =>
        selectedProducts.current[treatmentProduct.product.id] >=
        treatmentProduct.quantity
    );

    setSelectedItems(items.map((item) => item.product.id));
  };

  const onDeselecteProduct = (product) => {
    const deselected = stockForTreatment.find(
      (item) => item.stockId === product.value
    );

    setSelectedItems((item) =>
      item.filter((productId) => productId !== deselected.productId)
    );

    const qty = selectedProducts.current[deselected.productId];
    selectedProducts.current[deselected.productId] = qty - 1;
  };

  const onSubmit = async (formValues) => {
    formValues.status = status;

    if (formValues?.treatmentProducts) {
      formValues.stockIds = formValues.treatmentProducts.map(
        (product) => product.stockId.value
      );
    }

    if (status === finished?.alias)
      setResponse(
        await dispatch(finishAppointment(appointmentById?.id, formValues))
      );
    else
      setResponse(
        await dispatch(setOnProcess(appointmentById?.id, formValues))
      );
  };

  return (
    <Card size="small" bordered={true} style={cardConfig}>
      {!purchasedProduct && (
        <Alert
          message={appointmentConst.PURCHASED_PRODUCT_ALERT.TITLE}
          description={appointmentConst.PURCHASED_PRODUCT_ALERT.DESCRIPTION}
          showIcon
          type="warning"
          action={
            <Button
              size="small"
              icon={<RedoOutlined />}
              onClick={() => setRefreshAppointment(!refreshAppointment)}
            >
              {buttonLabel.refresh}
            </Button>
          }
        />
      )}

      <Form autoComplete="off" onFinish={onSubmit} form={form} {...formConfig}>
        {needConsent && (
          <Form.Item name="consent" label="Consentimiento">
            <Switch
              checked={consent}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={setConsent}
              disabled={appointmentById?.consent}
            />
          </Form.Item>
        )}

        {(consent || !needConsent) && (
          <FormItem name="status" label="Status">
            <Select
              placeholder={placeHolders.status}
              onChange={setStatus}
              options={statusFiltered}
            ></Select>
          </FormItem>
        )}

        {[inProcess?.alias, finished?.alias].includes(status) && (
          <Form.Item name="attendedById" label={`${roleLabel}:`}>
            <CustomSelect
              placeholder={placeHolders.assign}
              options={users?.map((user) => ({
                value: user?.id,
                label: `${user?.name} ${user?.lastName}`,
              }))}
            ></CustomSelect>
          </Form.Item>
        )}

        {status === finished?.alias && (
          <>
            <Form.Item
              {...formConfig}
              name="sessions"
              label="Sesiones"
              rules={[
                { required: true, message: messages.required('Sesiones') },
              ]}
            >
              <Select
                allowClear
                onChange={onSessionsChange}
                options={sessions}
              ></Select>
            </Form.Item>

            {areas.length > 0 && (
              <Form.List {...formConfig} disabled name="sessionAreas">
                {(fields) => (
                  <div>
                    {fields.map((field) => (
                      <Form.Item
                        {...formConfig}
                        name={[field.name, 'areaId']}
                        label={`Areas sesión ${field.name + 1}`}
                        rules={[
                          {
                            required: true,
                            message: messages.required('Areas'),
                          },
                        ]}
                      >
                        <CustomSelect
                          placeholder={placeHolders.aplication}
                          allowClear
                          onChange={onAreaChange}
                          options={areas?.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))}
                        ></CustomSelect>
                      </Form.Item>
                    ))}
                  </div>
                )}
              </Form.List>
            )}

            <Form.List name="treatmentProducts">
              {(fields) => (
                <div className="products-container">
                  {fields.map((field) => (
                    <>
                      <Form.Item
                        {...oneColumnFormConfig}
                        label="Producto"
                        name={[field.name, 'stockId']}
                        rules={[
                          {
                            required: true,
                            message: messages.required('Producto'),
                          },
                        ]}
                      >
                        <CustomSelect
                          placeholder={placeHolders.product}
                          onChange={onProductChange}
                          onDeselect={onDeselecteProduct}
                          labelInValue={true}
                          options={filteredProducts.map((product) => ({
                            value: product?.stockId,
                            label: `SKU: ${product?.sku} - Lote: ${product?.allotment} - ${product?.name}`,
                            productId: product?.productId,
                            stock: product?.stock,
                          }))}
                        ></CustomSelect>
                      </Form.Item>
                    </>
                  ))}
                </div>
              )}
            </Form.List>
          </>
        )}

        {requiredConsent && (
          <Button
            type="default"
            onClick={() => downloadConsent()}
            icon={<DownloadOutlined />}
          >
            {buttonLabel.consent}
          </Button>
        )}

        <Button type="primary" htmlType="submit" className="btn-form">
          {buttonLabel.save}
        </Button>
      </Form>
    </Card>
  );
};
